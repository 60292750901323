<template>
    <div>
        <b-modal
            v-model="modalShow"
            modal-class="filter-dialog"
            ok-only
            ok-title="Ok"
            @ok="sendParams()"
            @hide="$emit('clearAction')"
        >
            <div v-if="fields">
                <b-form-group
                    v-for="(item,index) in fields"
                    :key="index"
                    :label="$t(item.label)"
                    class="me-4"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-input v-if="item.type === 'text'" type="text" v-model="item.value"/>
                    <v-select
                        v-if="item.type === 'entity' || item.type === 'customer' || item.type === 'employee' || item.type === 'enum' || item.type === 'importantUpdate'"
                        v-model="item.value"
                        :options="item.list"
                        :multiple="item.multiple ? true : false"
                    />
                    <v-select
                        v-if="item.type === 'station'"
                        v-model="item.value"
                        :options="item.list"
                        taggable
                    />
                    <datepicker
                        v-if="item.type === 'date'"
                        v-model="item.value"
                        :enableTimePicker="false"
                        format="dd.MM.yyyy"
                    />
                    <datepicker
                        v-if="item.type === 'datetime'"
                        v-model="item.value"
                        :enableTimePicker="true"
                        format="dd.MM.yyyy HH:mm"
                    />
                    <datepicker
                        v-if="item.type === 'range'"
                        v-model="item.value"
                        range
                        :enableTimePicker="false"
                        format="dd.MM.yyyy"
                    />
                    <datepicker
                        v-if="item.type === 'rangeStatistics'"
                        v-model="item.value"
                        range
                        :enableTimePicker="false"
                        format="dd.MM.yyyy"
                    />
                </b-form-group>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";

export default {
    components: {
        vSelect,
        Datepicker
    },
    props: {
        fieldsArray: {
            type: Array, default() {
            }
        },
        action: {type: Boolean, default: false},
    },
    watch: {
        action() {
            this.modalShow = this.action
        },
    },
    mixins: [ResourceUtils, ListUtils],
    data() {
        return {
            modalShow: false,
            fields: this.fieldsArray
        }
    },
    created() {
        this.fields.forEach(element => {
            if (element.type === 'entity' || element.type === 'station') {
                element.list = []
                this.getResourceList(this[element.entityName], element.list)
            }
            if (element.type === 'importantUpdate') {
                element.list = []
                this.getResourceList(this.$Documents, element.list, {importantUpdate: true})
            }
            if (element.type === 'enum') {
                element.list = []
                this.getEnumList(element.enumName, element.list)
            }
            if (element.type === 'customer') {
                element.list = []
                this.getUserList({params: {role: "ROLE_CUSTOMER"}}, element.list);
            }
            if (element.type === 'employee') {
                element.list = []
                this.getUserList({params: {role: "ROLE_EMPLOYEE"}}, element.list);
            }
        });
    },
    methods: {
        sendParams() {
            const params = []
            this.fields.forEach(element => {
                let value = element.value
                if (element.type === 'range' && value) {
                    params.push(
                        {
                            name: element.name + '[after]',
                            value: value[0].getFullYear() + '-' + (value[0].getMonth() + 1) + '-' + value[0].getDate() + 'T00:00:00'
                        }
                    )
                    params.push(
                        {
                            name: element.name + '[before]',
                            value: value[1].getFullYear() + '-' + (value[1].getMonth() + 1) + '-' + value[1].getDate() + 'T23:59:59'
                        }
                    )

                    return
                }
                if (element.type === 'rangeStatistics' && value) {
                    params.push(
                        {
                            name: 'fromDate[after]',
                            value: value[0].getFullYear() + '-' + (value[0].getMonth() + 1) + '-' + value[0].getDate() + 'T00:00:00'
                        }
                    )
                    params.push(
                        {
                            name: 'toDate[before]',
                            value: value[1].getFullYear() + '-' + (value[1].getMonth() + 1) + '-' + value[1].getDate() + 'T23:59:59'
                        }
                    )

                    return
                }
                if ((element.type === 'entity' || element.type === 'employee' || element.type === 'customer') && value) {
                    value = element.value.numberId
                    if (element.multiple) {
                        value = []
                        element.value.forEach(el => {
                            value.push(el.numberId)
                        });
                    }
                }
                if (element.type === 'importantUpdate' && value) {
                    value = element.value.numberId
                }
                if (element.type === 'enum' && value) {
                    value = element.value.id
                    if (element.multiple) {
                        value = []
                        element.value.forEach(el => {
                            value.push(el.id)
                        });
                    }
                }
                if (element.type === 'station' && value) {
                    value = element.value.label
                }
                params.push(
                    {
                        name: element.name,
                        value: value
                    }
                )
            });
            this.$emit("clearAction")
            this.$emit("paramsChange", params)
        }
    }
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
