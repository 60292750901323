<template>
    <router-link v-if="$helper.userCapability('update',$route)" :to="{ path, query: { use: 'true' } }"
                 class="custom-btn" title="Use item">
        <BootstrapIcon icon="wrench" size="1x"/>
    </router-link>
</template>

<script>
export default {
    name: "EditBtn",
    props: {
        path: {type: String, default: ""},
    },
};
</script>
